import {
    Container,
    Divider,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    Snackbar,
    Stack,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import {useState} from "react";
import IconButton from "@mui/material/IconButton";
import {
    americaProvinceCodeToNameMap,
    canadaProvinceCodeToNameMap,
    generateRandomString,
    generateUniqueName, getCountryCode,
    internationalShipmentTypeList,
    transportationList
} from "../../utils/Helper";
import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import InventoryIcon from '@mui/icons-material/Inventory';
import {CustomsBrokerageItem} from "./CustomsBrokerageItem";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {CustomBrokerageRating} from "./CustomBrokerageRating";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        // gap: '15px',
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        borderRadius: '10px',
        marginBottom: '40px'
    },
    ShippingOrderHeading: {
        padding: '20px 20px 0',
        marginBottom: '20px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    ShippingOrderSubHeading: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '10px'
    },
    ShippingOrderContent: {
        padding: '20px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 32,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 20,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 16,
        height: 16,
        borderRadius: 8,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const requiredProperties = ["description", "quantity", "weight", "weightUnit", "UOM", "currency", "priceEach", "hscode", "originCountryCode"];
const validateRequiredProperties = ["quantity", "weight", "priceEach"];

export const CustomsBrokerage = () => {

    const CanadianProvinceList = Object.entries(canadaProvinceCodeToNameMap).map(([key, value]) => [key, value]);
    const AmericanStateList = Object.entries(americaProvinceCodeToNameMap).map(([key, value]) => [key, value])

    const [crossBorderFee, setCrossBorderFee] = useState(null);
    const [validation, setValidation] = useState(true);
    const [errorList, setErrorList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [metric, setMetric] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [crossBorder, setCrossBorder] = useState({
        tranMode: "INT_TRUCK",
        shipmentType: "COMMERCIAL",
        exportCountryCode: 'US',
        importCountryCode: 'CA',
        importProvinceCode: 'BC'
    });
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getCrossBorderFee = async () => {
        setCrossBorderFee(null)
        setErrorList([]);
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/brokerage/admin/landedCostByServiceName`;

        let data = {
            serviceName: "ups",
            importCountryCode: crossBorder?.importCountryCode,
            importProvince: crossBorder?.importProvinceCode,
            exportCountryCode: crossBorder?.exportCountryCode,
            transModes: crossBorder?.tranMode,
            shipmentType: crossBorder?.shipmentType,
            shipmentItems: itemList
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log('[getCrossBorderFee] result', result);
            if (result?.data?.error?.errors?.length > 0) {
                setErrorMessage(`Fail to get cross border fees. ${result?.data?.error?.errors[0]?.message}`);
                handleErrorMessage();
                setErrorList(result?.data?.error?.errors);
                return false;
            } else {
                setErrorMessage('');
                setCrossBorderFee(result?.data);
                return true;
            }
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Fail to get cross border fees.');
            handleErrorMessage();
            return false;
        } finally {
            setLoading(false);
        }
    }

    const handleMetric = (event) => {
        setMetric(event.target.checked);
    }

    const handleAddItem = () => {

        const itemId = generateRandomString(8);
        const newItem = {
            description: `item-${itemId}`,
            sku: '',
            weight: null,
            weightUnit: metric ? 'kg' : 'lb',
            UOM: 'BOX',
            hscode: null,
            originCountryCode: 'CN',
            currency: 'CAD',
            priceEach: null,
            quantity: '1',
            itemId: itemId
        }
        setItemList(prevState => [...prevState, newItem]);
    }

    const updateItemList = (updateItem) => {
        // console.log('[updateItemList] updateItem', updateItem);
        // console.log('[updateItemList] itemList', itemList);

        const updatedItemList = itemList?.map(item => {
            if (item?.itemId === updateItem?.itemId) {
                return updateItem
            }
            return item
        });

        // console.log('[updateItemList] updatedItemList', updatedItemList);

        setItemList(updatedItemList);
    }

    const duplicateItem = (duplicatedItem) => {
        const itemId = generateRandomString(8);
        const updatedItem = {...duplicatedItem};
        updatedItem.itemId = itemId;
        const description = generateUniqueName(updatedItem.description, itemList);
        updatedItem.description = description;
        setItemList(prevState => [...prevState, updatedItem]);
    }

    const removeItem = (removedItem) => {
        const itemId = removedItem?.itemId;
        const updatedItemList = itemList?.filter(item => item?.itemId !== itemId);
        setItemList(updatedItemList);
    }

    const handleTranMode = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            tranMode: event.target.value
        }))
    }

    const handleShipmentType = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            shipmentType: event.target.value
        }))
    }

    const handleExportCountryCode = event => {
        setCrossBorder(prevState => ({
            ...prevState,
            exportCountryCode: event.target.value
        }))
    }

    const handleImportCountryCode = event => {
        setCrossBorder(prevState => ({
            ...prevState,
            importCountryCode: event.target.value,
            importProvinceCode: ""
        }))
    }

    const handleImportProvinceCode = event => {
        setCrossBorder(prevState => ({
            ...prevState,
            importProvinceCode: event.target.value
        }))
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const hasMissingValues = itemList.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = itemList.some(object => validateRequiredProperties.some((property) => (object[property]) <= 0));

        itemList?.length === 0 && setErrorMessage(prevState => prevState + "Your item list is empty.");
        hasMissingValues && setErrorMessage(prevState => prevState + "Your item list has missing one or more required fields.");
        hasValidateValues && setErrorMessage(prevState => prevState + "Your item list has one or more invalid fields.");
        !crossBorder?.importProvinceCode && setErrorMessage(prevState => prevState + "Import province/state is missing.");

        if (!hasMissingValues && !hasValidateValues && itemList.length !== 0 && crossBorder?.importProvinceCode) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleGetRating = async () => {
        const validationResult = validate();
        setValidation(validationResult);
        if (validationResult) {
            await getCrossBorderFee();
        }
    }

    console.log('[CustomsBrokerage] item list', itemList);
    console.log('[CustomsBrokerage] crossBorder', crossBorder);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingOrderRoot}>
                <Box sx={styles.ShippingOrderHeading}>
                    <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                        Customs Brokerage
                    </Typography>
                    <Box sx={styles.ShippingOrderSubHeading}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <AntSwitch
                                checked={metric}
                                onChange={handleMetric}
                            />
                            <Typography>
                                Metric (kg)
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton
                                sx={{color: '#609966'}}
                                onClick={handleAddItem}
                            >
                                <InventoryIcon/>
                            </IconButton>
                            <Typography>
                                Add New Item
                            </Typography>
                        </Stack>
                    </Box>
                </Box>
                <Divider/>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        {
                            itemList?.length === 0 ?
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '1px solid #B7B7B7',
                                        padding: '20px',
                                        borderRadius: '10px'
                                    }}>
                                        <Typography
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: '600',
                                                color: '#7F8487',
                                                marginBottom: '10px'
                                            }}>
                                            Your item list is empty
                                        </Typography>
                                        <IconButton
                                            sx={{color: '#609966'}}
                                            onClick={handleAddItem}
                                        >
                                            <InventoryIcon/>
                                        </IconButton>
                                        <Typography style={{fontSize: '12px', fontWeight: '600', color: '#609966'}}>
                                            Add new item
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                itemList?.map((item, index) =>
                                    <Grid item xs={12} key={index}>
                                        <CustomsBrokerageItem
                                            item={item}
                                            index={index}
                                            metric={metric}
                                            updateItemList={updateItemList}
                                            duplicateItem={duplicateItem}
                                            removeItem={removeItem}
                                            errorList={errorList}
                                            validation={validation}/>
                                    </Grid>
                                )
                        }
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Export to Country
                                </FormLabel>
                                <Select
                                    value={crossBorder?.importCountryCode}
                                    size='small'
                                    style={{textAlign: 'left'}} // font size of input text
                                    onChange={handleImportCountryCode}
                                    // error={!itemInfo?.originCountryCode}
                                >
                                    <MenuItem value={'CA'}>Canada</MenuItem>
                                    <MenuItem value={'US'}>USA</MenuItem>
                                </Select>
                                {/*<FormHelperText*/}
                                {/*    sx={{*/}
                                {/*        color: "error.main",*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    {!itemDetail?.originCountryCode && intl.get('CROSS_BORDER.REQUIRED')}*/}
                                {/*</FormHelperText>*/}
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Export to Province/State
                                </FormLabel>
                                <Select
                                    value={crossBorder?.importProvinceCode}
                                    size='small'
                                    style={{textAlign: 'left'}} // font size of input text
                                    onChange={handleImportProvinceCode}
                                    // error={!itemInfo?.originCountryCode}
                                >
                                    {
                                        crossBorder?.importCountryCode === 'CA' ? (
                                            CanadianProvinceList.map(value => {
                                                return <MenuItem value={value[0]}>{value[1]}</MenuItem>
                                            })) : (AmericanStateList.map(value => {
                                            return <MenuItem value={value[0]}>{value[1]}</MenuItem>
                                        }))
                                    }
                                </Select>
                                <FormHelperText
                                    sx={{
                                        color: "error.main",
                                    }}
                                >
                                    {!crossBorder?.importProvinceCode && "Required"}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Import From Country
                                </FormLabel>
                                <Select
                                    value={crossBorder?.exportCountryCode}
                                    size='small'
                                    style={{textAlign: 'left'}} // font size of input text
                                    onChange={handleExportCountryCode}
                                    // error={!itemInfo?.originCountryCode}
                                >
                                    {
                                        getCountryCode()?.map(v => {
                                            return <MenuItem value={v.code}>{v.name}</MenuItem>
                                        })
                                    }
                                </Select>
                                {/*<FormHelperText*/}
                                {/*    sx={{*/}
                                {/*        color: "error.main",*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    {!itemDetail?.originCountryCode && intl.get('CROSS_BORDER.REQUIRED')}*/}
                                {/*</FormHelperText>*/}
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Transportation Mode
                                </FormLabel>
                                <Select
                                    value={crossBorder?.tranMode}
                                    onChange={handleTranMode}
                                    size='small'
                                    style={{textAlign: 'left'}} // font size of input text
                                >
                                    {
                                        transportationList.map(value => {
                                            return <MenuItem value={value.code}>{value?.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Shipment Type
                                </FormLabel>
                                <Select
                                    value={crossBorder?.shipmentType}
                                    onChange={handleShipmentType}
                                    size='small'
                                    style={{textAlign: 'left'}} // font size of input text
                                >
                                    {internationalShipmentTypeList.map(value => {
                                        return <MenuItem value={value}>{value}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                // gap: '10px',
                                justifyContent: 'flex-end'
                            }}>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        },
                                    }}
                                    loading={loading}
                                    onClick={handleGetRating}
                                >
                                    <Typography sx={{textTransform: 'none'}}>
                                        Get Rate
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                {
                    (crossBorderFee) &&
                    <Box sx={styles.ShippingOrderContent}>
                        <CustomBrokerageRating crossBorderFee={crossBorderFee}/>
                    </Box>
                }
            </Box>
        </Container>
    )
}